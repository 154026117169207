"use client";

import { useEffect, useState } from "react";
import { useChronology } from "@/contexts/chronology/chronology.context";
import type { ZonedDateTime } from "@/ts/date-time/date-time.types";
import { PublicationDateTimeLoader } from "../PublicationDateTimeLoader/PublicationDateTimeLoader.component";
type ShortPublicationDateTimeProps = {
  readonly className?: string;
  readonly zonedDateTime: ZonedDateTime;
};
export function ShortPublicationDateTime({
  className,
  zonedDateTime
}: ShortPublicationDateTimeProps) {
  const {
    isToday,
    toShortDateWithoutYearString,
    toShortTimeString
  } = useChronology();
  const [formattedDateTime, setFormattedDateTime] = useState<string | null>(null);
  useEffect(() => {
    // Please note that because we are using `new Date()` here, we have
    // to wrap it in a `useEffect` hook to avoid hydration issues.
    // We can safely use `new Date()` in the browser because time
    // zone information is specified in the `zonedDateTime` prop.
    const dateToFormat = new Date(zonedDateTime);
    const dateTime = isToday(dateToFormat) ? toShortTimeString(dateToFormat) : toShortDateWithoutYearString(dateToFormat);
    setFormattedDateTime(dateTime);
  }, [isToday, toShortDateWithoutYearString, toShortTimeString, zonedDateTime]);
  return formattedDateTime !== null ? <time className={className} dateTime={zonedDateTime} data-sentry-component="ShortPublicationDateTime" data-sentry-source-file="ShortPublicationDateTime.component.tsx">
      {formattedDateTime}
    </time> : <PublicationDateTimeLoader className={className} data-sentry-element="PublicationDateTimeLoader" data-sentry-component="ShortPublicationDateTime" data-sentry-source-file="ShortPublicationDateTime.component.tsx" />;
}