"use client";

import clsx from "clsx";
import NextImage from "next/image";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { Link } from "@/components/Link/Link.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { imageLoader } from "@/utilities/image-loader";
type ArticleTeaserListItemProps = {
  readonly model: ArticleTeaserModel;
  readonly position?: string;
  readonly imageVisibility?: "lg:hidden";
};
export function ArticleTeaserListItem({
  model: {
    content,
    requiresSubscription
  },
  position,
  imageVisibility
}: ArticleTeaserListItemProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  const {
    kicker,
    html
  } = content.headline;
  return <article className="grid auto-cols-fr grid-flow-col gap-x-2" data-k5a-pos={position} data-sentry-component="ArticleTeaserListItem" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
      <div className={clsx("col-span-2 lg:col-span-3", imageVisibility)}>
        {content.supportingImage !== null ? <Link className={clsx("relative block aspect-4/3 h-full w-full")} href={content.targetUrl} position="img">
            <NextImage alt={content.supportingImage.alternativeText} className="bg-whisper object-cover" loader={imageLoader(serverUrl)} sizes={`(max-width: ${tailwindScreenSize.md}) 40vw, 230px`} src={content.supportingImage.aspectRatio4By3} fill />
          </Link> : null}
      </div>

      <div className="col-span-3 lg:col-span-9">
        <Link className="mb-2 block hover:underline" href={content.targetUrl} position="head" data-sentry-element="Link" data-sentry-source-file="ArticleTeaserListItem.component.tsx">
          <h1 className="hyphens-manual font-heading text-xl font-theme-heading-weight lg:text-2xl">
            {kicker !== null ? <span className="text-primary">{kicker} </span> : null}
            <span dangerouslySetInnerHTML={{
            __html: html
          }} />
          </h1>
        </Link>

        {content.lead !== null ? <Link className="hidden text-base lg:block" href={content.targetUrl} position="lead">
            <HtmlContent className="leading-snug text-dim transition-colors duration-150 hover:text-black" html={content.lead.html} />
          </Link> : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className="text-primary lg:mt-3" /> : null}
      </div>
    </article>;
}